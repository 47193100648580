/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import Header from "./header"
import TableOfContents from "./tableOfContents"
import "./layout.css"

const LayoutComponent = ({ children, uri }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___dateUpdated, frontmatter___datePublished, frontmatter___title] },
        filter: {frontmatter: { draft: { ne: true } }}
      ) {
        edges {
          node {
            id
            fields {
              path
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>
        <div id="rside">
          <div id="letterzone">
            <div id="letteractual">
              {children}
            </div>
          </div>
        </div>
        <div id="lside">
          <div id="indexzone" className="scrollfadezone">
            <div id="indexactual">
              <TableOfContents
                currentPath={uri}
                items={data.allMarkdownRemark.edges.map(({ node: { fields: { path}, frontmatter: { title } } }) => {
                  return { path, title }
                })}
              />
              <div
                className={classNames("letterlink", {
                  active: "/about" === (uri && uri.replace(/\/$/, ""))
                })}
                id="aboutlink"
              >
                <Link
                  to="/about/"
                >
                  About these Letters
                </Link>
              </div>
            </div>
            {/* <div id="aboutzone" className="desktopmode">
              <div
                className={classNames("letterlink", {
                  active: true // "/about" === (uri && uri.replace(/\/$/, ""))
                })}
                id="aboutlink"
              >
                <Link
                  to="/about/"
                >
                  About these Letters
                </Link>
              </div>
            </div>*/}
          </div>
        </div>
      </main>
    </>
  )
}

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  uri: PropTypes.string.isRequired,
}

class Layout extends React.Component {
  componentDidMount() {
    this.fadeOnScroll();
  }

  fadeOnScroll(ev) {
    function saturate (aa) {
      if (aa < 0) return 0;
      if (aa > 1) return 1;
      return aa;
    }
    const fade_factor = 30; // how many pixels to fade over
    const fade_buffer = 100; // how many pixels to fade 'early'
    let els = Array.from(document.querySelectorAll('.scrollfadezone'))
    for (const el of els) {
      el.data_prevscroll = -1; // force calculation on first animation frame
    }
    //
    function freshScrollers (ev) {
      for (const el of els) {
        if (el.data_prevscroll === el.scrollTop) continue;
        let phonemode = getComputedStyle(el).overflowY !== 'auto'
        let refrc = el.getBoundingClientRect();
        // it scrolled..
        let subels = Array.from(el.querySelectorAll('.sf')) // sf means scroll-fade
        for (const subel of subels) {
          let rc = subel.getBoundingClientRect()
          subel.style.opacity = phonemode ? 1 : saturate(
            Math.min(
              ((rc.top - refrc.top) - fade_buffer) / fade_factor,
              ((refrc.bottom - rc.bottom) - fade_buffer) / fade_factor)
            )
            subel.style.pointerEvents = subel.style.opacity !== 0
          }
        }
        requestAnimationFrame(freshScrollers)
      }
      freshScrollers()
    }

  render() {
    const { children, uri } = this.props;
    return (
      <LayoutComponent uri={uri}>
        {children}
      </LayoutComponent>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  uri: PropTypes.string.isRequired,
}

export default Layout
