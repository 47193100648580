import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <Link className="hside" to="/">
      <div className="h-prime">
        Love
        <br/>
        <br/>
        to the
      </div>
      <div className="h-prime">
        <br/>
        Letters
        <br/>
        <br/>
        Void
      </div>
    </Link>
    <div className="hside">
      <a className="phonemode" href="#indexactual">&nbsp;All&nbsp;<small>&#x25BC;</small>&nbsp;</a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
