import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

const TableOfContents = ({ currentPath, items }) => (
  <nav>
      { items.map(({ path, title }, index) => {
        return (
          <div
            className={classNames("sf letterlink", {
              active: (path.replace(/\/$/, "") === (currentPath && currentPath.replace(/\/$/, "")))
                || ((currentPath && currentPath.replace(/\/$/, "")) === "" && index === 0)
            })}
            key={path}
          >
            <Link
              to={path}
            >
              <span>{title}</span>
            </Link>
          </div>
        )
      })}
  </nav>
)

TableOfContents.propTypes = {
  currentPath: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
}

TableOfContents.defaultProps = {
  items: [],
}

export default TableOfContents
